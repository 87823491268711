.App {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
}

.App-logo {
  height: 4rem;
  pointer-events: none;
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77vh;
}

.Wrapper-box {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.Heading {
  color: #383838;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
}

.Redeem-image {
  width: 20rem;
  max-width: 100%;
  display: block;
  text-align: center;
  margin: 50px auto 10px auto;
}

.Secondary-text {
  margin: 30px auto;
  max-width: 250px;
}

.input-mask {
  font-family: 'Proxima Nova', 'Open Sans', sans-serif;
  font-weight: 700;
  width: 100%;
  text-align: center;
  height: 60px;
  font-size: 20px;
  color: #333030;
  max-width: 400px;
  margin: 0 auto;
}

.form-input {
  width: 100%;
  border: none;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: 0 5px 0 0 rgba(76, 18, 158, 0.2);
  box-shadow: 0 5px 0 0 rgba(76, 18, 158, 0.2);
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  transition-property: box-shadow, border-color, -webkit-transform;
  -webkit-transition-property: border-color, -webkit-transform,
    -webkit-box-shadow;
  transition-property: border-color, -webkit-transform, -webkit-box-shadow;
  -o-transition-property: transform, box-shadow, border-color;
  transition-property: transform, box-shadow, border-color;
  transition-property: transform, box-shadow, border-color, -webkit-transform,
    -webkit-box-shadow;
  transition-property: transform, box-shadow, border-color, -webkit-transform;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-box-shadow: 0 0px 0 0 rgba(76, 18, 158, 0.3);
  box-shadow: 0 0px 0 0 rgba(76, 18, 158, 0.3);
}

.form-input::-webkit-input-placeholder {
  color: #a6b4c3;
  color: #8d8d8d;
  opacity: 1;
}

.form-input:-ms-input-placeholder {
  color: #a6b4c3;
  color: #8d8d8d;
  opacity: 1;
}

.form-input::-ms-input-placeholder {
  color: #a6b4c3;
  color: #8d8d8d;
  opacity: 1;
}

.form-input::placeholder {
  color: #a6b4c3;
  color: #8d8d8d;
  opacity: 1;
}

.form-input:disabled,
.form-input[readonly] {
  background-color: #f4f4f4;
  opacity: 1;
}

.form-input:focus {
  border-color: #985dec;
  outline: 0;
}

.form-input--rounded {
  background-color: transparent;
  padding: 0.6rem 0.8rem;
  margin: 0 0.4rem;
  font-size: 1rem;
  border-radius: 30px;
  border: 1px solid #a6b4c3;
}

.form-input--rounded:focus {
  border-color: #985dec;
  outline: 0;
  border-radius: 30px;
}

.form-input--transparent {
  background-color: transparent;
}

.form-input--bg-grey {
  background-color: #f6f7f7;
  border: none;
  font-size: 1rem;
}

.form-input--bg-grey .form-input__icon {
  border: none;
}

.form-input__group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.form-input__group .form-input {
  margin-right: 0 !important;
  padding-right: 0.6rem;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1rem;
}

.form-input__group .form-input + .form-input__icon {
  color: #a6b4c3;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  line-height: 2.9;
  border-left: none;
  padding: 0 0.75rem 0 0.1rem;
  border: 1px solid #a6b4c3;
  border-left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
}

.form-input__group .form-input + .form-input__icon a {
  color: #a6b4c3;
}

.form-input__group .form-input + .form-input__icon--bg-grey {
  border: 0;
  background-color: #f6f7f7;
}

.form-input__group .form-input:focus {
  border-color: #985dec;
  outline: 0;
  border-right: none;
}

.form-input__group .form-input:focus + span {
  border-color: #985dec;
  outline: 0;
  border-left: none;
  color: #985dec;
}

.form-input__group .form-input:focus + span a {
  color: #985dec;
  fill: #985dec;
}

.form-input__group .form-input--rounded {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.form-input__group .form-input--rounded + .form-input__icon {
  color: #a6b4c3;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  line-height: 2.4;
  border-left: none;
  padding: 0 0.75rem 0 0.1rem;
  border: 1px solid #a6b4c3;
  border-left: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.form-input__group .form-input--rounded:focus {
  border-color: #985dec;
  outline: 0;
}

.form-input__group .form-input--rounded:focus + span {
  border-color: #985dec;
  outline: 0;
  border-left: none;
  color: #985dec;
  fill: #985dec;
}

.button {
  display: inline-block;
  font-weight: 600;
  color: #985dec;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 1.75rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 2px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .button {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.button:hover {
  color: #823be8;
  text-decoration: none;
}

.button:focus,
.button.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem;
  box-shadow: 0 0 0 0.2rem;
}

.button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.button.disabled,
.button:disabled {
  opacity: 0.65;
}

a.button.disabled,
fieldset:disabled a.button {
  pointer-events: none;
}

.button--gradient-primary {
  color: #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#985dec),
    to(#6d1be2)
  );
  background: -webkit-linear-gradient(left, #985dec 0%, #6d1be2 100%);
  background: -o-linear-gradient(left, #985dec 0%, #6d1be2 100%);
  background: linear-gradient(90deg, #985dec 0%, #6d1be2 100%);
  border: transparent;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  transition-property: box-shadow, -webkit-transform;
  -webkit-transition-property: -webkit-transform, -webkit-box-shadow;
  transition-property: -webkit-transform, -webkit-box-shadow;
  -o-transition-property: transform, box-shadow;
  transition-property: transform, box-shadow;
  transition-property: transform, box-shadow, -webkit-transform,
    -webkit-box-shadow;
  transition-property: transform, box-shadow, -webkit-transform;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-box-shadow: 0 0px 0 0 rgba(109, 27, 226, 0.3);
  box-shadow: 0 0px 0 0 rgba(109, 27, 226, 0.3);
}

.button--gradient-primary:hover {
  color: #ffffff;
}

.button--gradient-primary.disabled,
.button--gradient-primary:disabled {
  color: #fff;
  background-color: #985dec;
  border-color: #985dec;
}

.button--gradient-primary:not(:disabled):not(.disabled):hover {
  color: #ffffff;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 5px 0 0 rgba(109, 27, 226, 0.3);
  box-shadow: 0 5px 0 0 rgba(109, 27, 226, 0.3);
}

.button--gradient-primary:not(:disabled):not(.disabled):active {
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-box-shadow: 0 0px 0 0 rgba(109, 27, 226, 0.3);
  box-shadow: 0 0px 0 0 rgba(109, 27, 226, 0.3);
}

.button--xsm {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1.2;
  border-radius: 6px;
}

.button--xlg {
  padding: 0.875rem 2rem;
  font-size: 1.25rem;
  line-height: 1.2;
  border-radius: 6px;
  min-width: 14rem;
}

.button--lg {
  padding: 0.875rem 2rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 1.5px;
  min-width: 9rem;
}

.button--sm {
  padding: 0.75rem 1.375rem;
  font-size: 0.75rem;
  line-height: 1.2;
  border-radius: 2px;
}

.button--rounded {
  border-radius: 1.5rem;
  margin-top: 35px;
}
