@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Reg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Sbold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./assets/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Proxima Nova', 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
